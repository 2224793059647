
:root {
  background:linear-gradient(to top, rgba(26, 26, 26, 0.5), rgb(0, 0, 0)),
}



html {
  box-sizing: border-box !important;
  font-family: 'Lato',Arial,Helvetica,sans-serif;
  color: #706f70;
  background: #000 !important;
  height: 100% !important;
  position: relative;
  width: 100% !important;
  overflow-x: hidden;
  
}

body {
  margin: 0;
  padding: 0px 4px;
  position: relative;
  /* overflow-x: hidden;
  overflow-y: scroll; */
}


.ver {
  overflow-x: hidden !important;
  background: #000;
}

.nover {
  display: none !important;
}



.App-container-joema {
    display: grid;
    background:linear-gradient( to bottom right, #1d2424, #05070a);
    width: 100%;
    height:100%;
    
    background-size: contain;
    background-repeat: no-repeat;
    background-size: 100%;
    /* overflow-y: hidden !important; */
   
  position: relative;
}

/* .App-container-joema::after{
    content:"";
    height: 100%;
    width: 100%;
    background: url(../imagenes/background/DDD.jpg);
    position: absolute;
    top: 0;
    z-index:0;
    background-size: contain;
    background-repeat: no-repeat;
    background-size: 100%;
    opacity: 0.30;
    filter: blur(230px);

} */
.Headerbar {
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    background: transparent;
    border-bottom: 0.1rem solid #dbdbdb4b;
    box-shadow:0px 3px 1px rgba(3, 4, 7, 0.808) !important ;
    display: block;
    transition: all 200ms ease;
    z-index: 3;
    background:  #00000072;
    backdrop-filter: blur(30px);
   
}

.Headerbar:hover{
    transition: all 150ms ease;
    background: #0000009d;
   
}


.search-love {
/* min-width: 500px !important; */
width: 100%;
}
.search-love div {
  padding: 0px 10px;
  color: #fffefcb9;
} 


.Headerbar-box {
    display: grid;
    grid-template-columns: auto 50% auto;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    position: relative;
    align-items: center;
    
}

.search-natural{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    border: 1px solid #212121;
    height: 100%;
    align-self: center;
    border-radius: 100px;
    width: 100%;
    max-width: 550px;
    min-width:260px;
    margin-left: auto;
    margin-right: auto ;
    backdrop-filter: blur(100px);
    /* box-shadow: 0px 0px 2px #232323; */
    background:#12121236;
    color:#fff;
    align-items: center;
}

.search-love {
  font-size: 0.9rem;
  opacity: 0.95;
  position: relative;
  padding: 2px 0px;
  cursor: pointer;
  /* box-shadow: 0px 0px 4px #111111; */
  transition: all 200ms ease;
  /* box-shadow:0px 0px 2px #fff5db28, 0px 0px 1px #fffffff0; */
}

.search-love:hover {
  box-shadow: 0px 0px 2px #ffefc2;
  transition: all 200ms ease;
}

.float-btn-joema {
  position: absolute;
  display: grid;
  padding: 0px 0px;
  height: 100%;
  width: 100%;
  z-index: -3;
  justify-content: end;
  align-items: center;
  

}

.sexy-border {
  border-right: 1px solid #e7ce7e;
  border-left: 1px solid #e7ce7e;
}

.float-btn-joema img {
  display: grid;
  height: 24px;
  width:24px;

  left: 30px;
  border-radius: 100px;
  margin-right: 20px;
  
}

.head-e-1{
    height: 100%;
    display: grid;
    align-items: center;
    text-align: left;
    color:#fff;
    font-size: 20px;
    font-weight: 700;
}


.head-e-2{
    height: auto;
    display: grid;
    align-items: center;
    text-align: center;
    font-size: 1rem;
    color: #fff;
}


.head-e-3{
    height: 100%;
    display: grid;
    align-items: center;
    text-align: right;
    color:#fff;
}

/* .Headerbar-box::after{
    content:'';
    height: 1px;
    width: 200%;
    position: absolute;
    bottom:0px;
    background:#212121;
    left:-300px;
}
 */

.Headerbar-box-box {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    column-gap: 10px;
    width: 95%;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    height: 50%;
}


.background-epic {
  
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
  background: #131313;
}

.eleme-bg-1 {
  top:0px;
  right:20px !important;
  background: #02266a96;
  height: 400px;
  width: 400px;
  filter: blur(910px);
  transform: rotate(45deg);
  position: absolute;
  animation: qpapa 7260ms ease-in-out 6ms infinite alternate-reverse !important;
}

.eleme-bg-3 {
  top:-90px;
  right:10% ;
  background: #000000be;
  height: 400px;
  width: 700px;
  filter: blur(330px);
  transform: rotate(35deg);
  position: absolute;
  transition: all 150ms ease;
  animation: cuartopapa 3260ms ease-in-out 6ms infinite alternate !important;
  
}

.eleme-bg-2 {
  top:-300px;
  left:0px !important;
  background: #21212165;
  height: 1000px;
  width: 1500px;
  filter: blur(20px);
  transform: rotate(35deg);
  position: absolute;
  

}

@keyframes cuartopapa {
  from{
    left:0%;
    opacity: 0.40;
  }
  to{
    left:80%;
    opacity: 1;
  }
}

@keyframes qpapa {
  from{
    left:100%;
    opacity: 1;
  }
  to{
    left:0%;
    opacity: 0.40;
  }
}



.Footerbar {
    background:#080808af;
    backdrop-filter: blur(300px);
    width: 100%;
    height: 50px;
    position: fixed;
    bottom: 0;
    border-top:  1px solid #dbdbdb36;
    padding-left: 20px;
    padding-right: 20px;
    z-index: 3;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    justify-content: space-between;
    transition: all 200ms ease;
}

.search-natural div {
  padding: 3px 15px;
}
/* .search-natural {
  padding: 0px 20px;
} */

@media screen and (min-width:0px) and (max-width:992px) {

  .float-btn-joema {
    padding: 0px 0px !important;
  }
  .search-natural {
    padding: 0px 0px;
  }
  .search-natural div {
    padding: 0px 0px;
  }
  
  .background-epic {
    height: 140px;
  }


  .windows-selection-hola{
    height: 80% !important;
  }
  
  .search-love {
 
 
    
    box-shadow: 0px 0px 4px #111111;
    transition: all 200ms ease;
    height: 100%;
  }
  .head-e-2 {
   
    
    
    padding: 4px;
  }

  .head-e-3 {
    height: 100%;
    padding:0px;
    display: flex;
align-items: center;
text-align: right;
color: #fff;
justify-content: center;
background: linear-gradient( to right, #040303, #0000);
backdrop-filter: blur(30px);
    
  }

  .head-e-77 {
    height: 100%;
    padding:7px;
    display: flex;
align-items: center;
text-align: right;
color: #000 !important;
justify-content: center;
background: linear-gradient( to right, #ffffff, #0000);
backdrop-filter: blur(30px);
    
  }
  .Headerbar-box{
    grid-template-columns: 100% !important;
    height: 120px;

    
   
  } 

  .Header-action-2 p {
    display: flex !important;
    flex-wrap: wrap !important;
    font-size: 15px !important;
    color :#000 !important;
    height: 100% !important;
    align-items: center !important;
  }
  .head-e-1 {
    display: none !important;
  }
  .Footerbar {
    grid-template-columns: 100% !important;
    justify-content: center !important;
    align-items: center !important;
    height: auto;
    padding-left: 0px !important;
    padding-right: 0px  !important;
    }
    /* /////////////////// responsive  */

    .foot-e-1 {
      display: grid;
      grid-template-columns: 25% 25% 25% 25% !important;
      padding: 10px;
      margin-left: auto !important;
      margin-right: auto !important;
      width: 100%;
      font-size: 14px;
      text-align: center;
      color: #aeaeae;
      cursor: pointer;
      transition: all 200ms ease;
      order: 1;
    }

    .foot-e-3 {
      display: grid;
      grid-template-columns: 33% 34% 33% !important;
      padding: 10px 0px;
      margin-left: auto !important;
      margin-right: auto !important;
      width: 100%;
      font-size: 14px;
      text-align: center;
      color: #aeaeae;
      cursor: pointer;
      transition: all 200ms ease;
      border-bottom:1px solid #feefb44a;
    } 

  .foot-e-1{
    max-width: 400px !important;
    
    text-align: center;
  }

  .foot-e-3{
    max-width: 700px !important;
    
  }
}


.container-pad {
    display: grid;
 
   
} 



.box-img-propiedades {
/* height: 260px; */
height: auto;
position: relative;
border-bottom: 1px solid transparent;
width: 100%;
/* margin: 0 auto; */


}

.propiedades {
    color: #fff;
    /* max-height: 1120px !important; */
    height: 100% !important;
    width: 100%;
   
    grid-template-columns: auto auto auto auto auto auto;
    display: grid;
    column-gap: 15px;
    justify-content: center;
    /* row-gap: 10px; */
    margin: 10px auto;
    padding-top: 50px !important;
    padding-bottom: 150px !important;
    
}
.go {
  max-height: 100px !important;
  background: #000;
  border-radius: 10px;
  width: 100%;
  position: relative;
  z-index: 9999;
  overflow-y: hidden !important;
} 

.kankan {
  width: 100%;
  display: block;
  height: 0px;
  background: black;
  transition: all 1400ms ease-in-out;
}

.kankan-active{
  height: 2900px;
  transition: all 800ms ease;
}

@media screen and (min-width:0px) and (max-width:500px){
  .propiedades {
    padding: 0 0;
    grid-template-columns: 100%;
    width: 100%;
    
  }
}

@media screen and (min-width:500px) and (max-width:766px){
  .propiedades {
    grid-template-columns: auto auto;
    /* width: 90%; */
   
  }
}

@media screen and (min-width:767px) and (max-width:992px){
  .propiedades {
    grid-template-columns: auto auto auto;
    /* width: 90%; */
  }
}

@media screen and (min-width:993px) and (max-width:1512px){
  .propiedades {
    grid-template-columns: auto auto auto auto;
    /* width: 90%; */
   
  }
}


.img-container-pro {
    display: grid;
    height: 250px !important;
    min-width: 280px !important;
    width:100% !important;
    border-radius: 10px !important;
    background-size: cover !important; 
    background-position: 70% 50% !important;
    position: relative !important;
} 

.tittle-label-prop {
display: grid;
grid-template-columns: 70% 30%;
align-items: center;
text-align: center;
padding-left: 8px;
} 

.tittle-label-prop-foot {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    text-align: center; 
    margin-top: 10px;
} 

.tittle-label-prop-foot h2 {
    font-size: 15px;
    text-align: left;
    padding-left: 8px;
}

.tittle-label-prop-foot h3 {
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}

.tittle-label-prop h2 {
    font-size: 15px;
    text-align: left;
} 

.normal-text {
    font-size: 13px;
    color: #9e9e9e;
    padding-left: 9px;
    padding-top: 2px;
}

.container-prop {
    width: 100% !important;
    height: 100vh;
    position: relative;
    z-index: 2;
    /* overflow-y: auto;
    overflow-x: hidden !important; */
} 

.propiedades-box {
    background: transparent;
    margin-bottom: 20px;
    margin-top: 0px;
    cursor: pointer;
    position: relative;
    transition: all 200ms ease !important;
    max-height: 100%;
    width: 100%;

    display: grid;
} 

.manual-slider{
  width: 100% !important;
  position: relative;
}

.img-container-pro{
  transition: all 200ms ease !important;
  
}

.img-container-pro::before{
  content: "";
  height: 100%;
  width: 100%;
  right:0px;
  position: absolute;
  background: linear-gradient( to top, #00000076, #0000);
  z-index: 0;
  opacity: 1;
 
} 

.propiedades-box:hover .prop-text-prop {
  transition: all 200ms ease;
  background:#00000059;
  /* box-shadow: 0px -5px 0px #000 ,0px -1px 30px #000; */
  /* top:-5px; */
}

/* .propiedades-box:hover
 
.img-container-pro::before{
  opacity: 1;
  transition: all 250ms ease;
}  */


.propiedades-box:hover  p {
  transition: all 250ms ease;
  color: #e7cf7e !important;
}

.box-img-propiedades{
  border-radius: 10px;
  height: auto;
  padding: 0px 0px;
}

.propiedades-box:hover .box-img-propiedades {
  /* border-bottom: 1px solid #ffdc88; */
  transition: all 730ms ease;
  /* box-shadow: 0px -5px 0px #000 ,0px -1px 30px #000; */
}

.propiedades-box:hover  .tittle-label-prop-foot h2 {
  color: #ffff !important;
  transition: all 250ms ease;
}

.propiedades-box:hover .img-container-pro {
    background-position: 0% 50% !important;
   /* transform:  rotate(30deg); */
    transition: all 200ms ease;
}

@media screen and (min-width:0px) and (max-width:992px) {
  .propiedades-box:hover .img-container-pro {
    background-position: 70% 50% !important;
   /* transform:  rotate(30deg); */
    transition: all 200ms ease;
}


}

.img-float {
    height: 20px;
    width: 20px;
    margin-left: auto;
    margin-top: 10px;
    margin-right: 10px;
    position: absolute;
    z-index: 1;
    right: 1px;
} 

.img-float-2 {
    height: 13px;
    width: 13px;
    opacity: 0.60;
    position: relative;
    transition: all 200ms ease;
} 

.img-float-2:hover {
    transition: all 200ms ease;
    opacity: 1;
}
.fix-bienes {
    background: #05070a !important;
    color: #fff !important;
}

.fix-bienes p {
    color: #fff !important;
} 

.botones-img {
    position: absolute;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 30px 30px;
    justify-content: space-between;
    align-items: center;
    top:0px;
    padding: 0px 10px;
    
} 


.botones-img button {
    height: 30px;
    border-style: none;
    background: #000000d6;
    border-radius: 100px;
    display: grid;
    align-items: center;
    cursor: pointer;
    color: #fff;

} 






  /* .slick-next {
    right: -55px !important;
  } */ 

  .Footer-right-Joe {
    display: grid;
    grid-template-columns: 70% 30%;
    position: relative;
    align-items: center;
    width: 100%;
    /* max-width: 215px;
    margin-right: auto;
    margin-left: auto; */
    color:#aeaeae;
    cursor: pointer;
    transition: all 200ms ease;
    font-size: 15px;
    line-height: 10px;
  } 

  .Footer-right-Joe:hover {
    color:#cfcfcf;
    transition: all 200ms ease;
  }

  .Footer-right-Joe:hover img {
    opacity: 1.00;
    transition: all 100ms ease;
  }


  .Footer-right-Joe img {
        display: grid;
        height: 16px;
        width: 16px;
        opacity: 0.50;
        transition: all 200ms ease;
  }

  .foot-e-3 {
    display: grid;
    grid-template-columns: auto auto auto;
    margin-left: auto;
    width: 100%;
    max-width: 500px;
  
  }


  .Footer-right-Center {
    display: grid;
    grid-template-columns: 30% 70%;
    position: relative;
    align-items: center;
    width: 100%;
    max-width: 75px;
    margin-right: auto;
    margin-left: auto;
    color:#aeaeae;
    cursor: pointer;
    transition: all 200ms ease;
    font-size: 15px;
    text-align: center;
  } 


  .Footer-right-Center:hover img {
    opacity: 1.00;
    transition: all 100ms ease;
  }


  .Footer-right-Center img {
        display: grid;
        height: 16px;
        width: 16px;
        opacity: 0.50;
        transition: all 200ms ease;
  }

  .Footer-right-Centerr {
    text-align: center;
    align-items: center;
    display: grid;
    grid-template-columns: 100%;
    color:#aeaeae;
    cursor: pointer;
    transition: all 200ms ease;
    font-size: 15px;
  }
  .Footer-right-Centerr:hover {
    color:#fff;
    cursor: pointer;
    transition: all 200ms ease;
  }


  .foot-e-1 {
    display: grid;
    grid-template-columns: 40% 20% 20% 20%;
    align-items: center;
    max-width: 500px;
    width: 100%;
    font-size: 14px;
    text-align: center;
    color:#aeaeae;
    cursor: pointer;
    transition: all 200ms ease;
  }

  .foot-e-1 p {
    white-space: nowrap;
    overflow: hidden;
  }
  .foot-e-1 p:hover {
    color: #fff;
    transition: all 150ms ease-in-out;
    
  } 

  
  .Logo-Givarts {
    display: grid;
    height: 40px;
width: 40px;
    margin-left: auto;
    margin-right: auto;
    padding: 0px 20px;
  }

  .givarts-pie{
    font-size: 15px !important;
  }
  .blackblack{
    color: #000 !important;
  }
  .blackblack h2{
    font-size: 0.80rem;
    color: #000000 !important;
    font-weight: 700 !important;
  }
  .blackblack p {
    font-size: 0.70rem !important;
    color: #696969 !important;
    padding-left: 8px !important;
  } 
  .airbox-joema {
    width: 90% !important;
  }
  .head-e-1 {
    display: grid;
    grid-template-columns: auto !important;
    
    font-weight: 300 !important;
    margin: 0px 30px;
    color:#aeaeae;
  } 
  .ask-ground {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
  } 
  .ask-ground h2{
    font-size: 0.80rem;
    color: #fff !important;
    font-weight: 700 !important;
  }
  .ask-ground p {
    font-size: 0.70rem !important;
    color: #696969 !important;
    padding-left: 8px !important;
  } 
  .robertodijo {
    color: #fff;
    white-space: nowrap;
    font-size: 0.8rem;
    top:4px;
    transition: all 200ms ease;
    position: relative;
  }

  .robertodijo-hijo {
    position: absolute;
    top:-10px;
    right:2px;
    width: 100%;
    font-size: 10px;
    color: #fff4ccb9;
  }
  .givarts-pie {
    color: #fff;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 4px;
    opacity: 0.50;
  } 

  .Header-action {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 0px;
    width: 100%;
    align-items: center;
    color:#aeaeae;
    white-space: nowrap;
    
  } 

  .Header-action p {
   
    display: flex;
    flex-wrap: nowrap;
    font-size: 15px;
    align-items: center;
  }

  .Header-action img {
    height: 16px;
    width: 16px;
    opacity: 0.50;
    margin: 0px 4px;
    transition: all 200ms ease;
  }

  .Header-action img:hover {
    opacity: 1.00;
    transition: all 200ms ease;
    cursor: pointer;
  } 

  .img-box-icon-joe {
    
    padding: 7px 7px !important;
  }


  .single-bar img {
    width: 16px;
    height: 16px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    
  }

  .single-bar {
    display: grid;
    grid-template-columns: auto auto ;
    max-width: 100%;
    background: #00000036;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    margin: 0px 0px;
    width: 100%;
    padding: 3px 7px;
    cursor: pointer;
    position: relative;
    backdrop-filter: blur(90px);
    /* box-shadow:0px 0px 2px #ffffffcd, 0px 0px 5px #000000f0; */
    transition: all 200ms ease;
  }

  .single-bar:hover {
    box-shadow:0px 0px 1px #ffd970cd, 0px 0px 5px #dfb2584c;
    transition: all 200ms ease;
  }
 

  .menu-leve-leve {
    display: grid;
    grid-template-columns: 100%;
    position: absolute;
    max-height:360px;
    height: auto;
    width: 300px;
    backdrop-filter: blur(120px);
    background: #00000028;
    border-radius: 10px;
    top: 50px;
    right: 0px;
    z-index: 99;
  }

 
  .menu-leve-leve div {
    display: grid;
    text-align: left;
    padding: 20px 0px;
  } 

  .menu-leve-leve p {
    display: grid;
    margin: 0;
    padding: 4px 20px;
    background: transparent;
  }

  .menu-leve-leve p:hover {
    background: #ffffff14;
  }

  .border-top {
    border-top: 1px solid #b4a4a44f;
  }

  .menu-container-right {
    display: grid;
    grid-template-columns: auto auto auto ;
    height: auto;

    
  } 
  .icon-option {
    display: grid;
    grid-template-columns: auto auto auto ;
    align-items: center;
   
    margin:0 0px;
    padding: 1px 15px;
    border-radius: 4px;
  }


  .icon-option img {
    height: 1rem;
    margin: 0 2px;
    width: 1rem;
    padding: 9px;
    cursor: pointer;
    background: #000000;
    backdrop-filter: blur(40px);
    border-radius: 100px !important;
  }

  .img-max-yeah {
    height: 20px !important;
    width: 20px !important;
    border-radius: 100px !important;
  } 

  /* .img-max-yeah::after {

  }
 */


 .pl-1 {
    padding-left: 10px !important;
 } 

 
.slick-prev:before { content: "‹" !important; }
.slick-next:before { content: "›" !important; }

.slick-list {
    border-radius: 15px !important;
}

.slick-prev {
    left: 10px !important;
    z-index: 3 !important;
   
  } 

  .slick-next {
    right: 15px !important;
    z-index: 3 !important;
  
  } 


  .slick-dots {
   
    bottom: 25px !important;
   
  }


 .propiedades-box:hover .slick-prev::before{
  transition: all 200ms ease;
  color: #fff0bf !important;
}  


.propiedades-box:hover  .slick-next::before {
  transition: all 200ms ease;
  color: #fff0bf !important;
} 

.propiedades-box:hover .slick-arrow::after {
  opacity: 0.40;
  transition: all 400ms ease;
  transform: scale(0) rotate(45deg);
}

 .slick-prev::before, .slick-next::before {
    font-family: 'slick';
    font-size: 58px !important;
    line-height: 1;
    color: #fff
     !important; 
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: scale(1.3) !important;
    text-shadow: 0px 0px 1px #000;
    z-index: 2;
    position: relative;
    top: -30px;
    left: 2px;
  }
  
  

  .slick-arrow::after {
    background: #0000002e !important;
    opacity: 0;
    content: '' !important;
    width: 100% !important;
    height: 100% !important;
 
    position: absolute;
    top:-3%;
    left:3%;
    transform:scale(0) rotate(-45deg);
    border-radius: 0px;
    z-index: 1 !important;
    border: 1px solid #e7cf7e5e;
    transition: all 200ms ease;
    box-shadow: 0px 0px 4px #000;
  } 
  .img-container-pro::after {
    background: linear-gradient( to bottom, #00000000, #00000000, #000);
    content: '' !important;
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    top:0%;
    left:-1%;
    border-radius: 0px;
    z-index: 1 !important;
    border-left: 1px solid #e7e6e61c;
    border-right: 1px solid #e7e6e623;
    transition: all 200ms ease;
    opacity: 0.10 !important;
  } 


  .img-container-pro::after :hover{
    transition: all 200ms ease;
    opacity: 0.20 !important;
    content: '' !important;
    width: 100% !important;
    height: 100% !important;
  } 


  .slick-dots li.slick-active button::before {
    opacity: .75;
    color: #fff !important;
    transition: all 200ms ease !important;
  } 

  .slick-dots li button::before {

    color: white !important;
    transition: all 200ms ease !important;
    
  } 


  /* //// aqui vamos a colocar la ventana de seleccion //// */

  .windows-selection {
    display: grid !important;
    height: 100vh !important;
    width: 100% !important;
    background: transparent !important;
    position: fixed !important;
    backdrop-filter: blur(60px)!important;
    background:#22222274 !important;
    top: 0;
    left: 0;
    align-items: start;
    overflow: auto;
  }
 
  .adelante {
    z-index: 1000 !important;
  }


  .windows-selection::after{
    content:"";
    height: 100%;
    width: 100%;
    /* background: url(../imagenes/background/DDD.jpg); */
    position: absolute;
    top: 0;
    z-index:-1;
    background-size: contain;
    background-repeat: no-repeat;
    background-size: 100%;
    opacity: 0.30;
    filter: blur(230px);

} 




.windows-selection-01 {
  display: grid !important;
  height: 100vh !important;
  width: 100% !important;
  background: transparent !important;
  position: absolute !important;
  
  background:#0000002e !important;
  top: 0;
  left: 0;
}


.box-images-joema{
  display: grid;
  border: 1px solid #ffffff3e;
  height: 567px;
  max-width: 1200px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  border-radius:10px;
  grid-template-columns: auto auto;
  column-gap: 0px;
}

@media  screen and (min-width:0px)  and (max-width:992px){
  
  .box-images-joema{
    display: grid;
    grid-template-columns: 100%;
    grid-template-columns: 100%;
  }

  .icon-card-yeah-xx {
    flex-wrap: wrap !important;
  }
  .icon-card-yeah-xx h3 {
    flex-wrap: wrap !important;
  }
  .box-img-2-joema {
    display: grid !important;
   
  }
}


/* .yeah-scroll {
  display: block;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
 
}  */

.yeah-scroll {
  /* display: grid;
  height: auto;
  position: relative; */
  width: 100%;
 
} 
.container-pad {
  overflow: auto !important;
  height: 100%;
  position: relative;
  display: grid;
  width: 100%;
  /* background: #51482b; */

}



.windows-selection {
  overflow: auto;
}

.box-header-joema-modal {
  display: grid;

  min-height: auto;
  max-width: 1200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  padding-top: 70px;
  border-radius:10px;
  grid-template-columns: 100%;
  
  column-gap: 0px;
  align-items: center;
  

}

.ground-ground{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}


.ground-ground p {
  padding-right: 10px;
}

.head-bienes {
  display: grid;
  grid-template-columns: 100%;
  text-align: left;
}




.head-bienes h2 {
  font-size: 2.3rem;
  color: #fff;
}




.body-bienes {
  display: grid;
  grid-template-columns: 60% 30%;
  justify-content: space-between;
}

.bar-bar-joema {
  border-bottom: 1px solid #ffffff09;
  width:100%;
  height: 67px;
  letter-spacing: 30px;
  text-align: center;
  align-items: center;
  display: grid;
  font-style: capitalize !important;
  position: fixed;
  color: #fff;
  top:0;

}

.bar-bar-joema p {
  z-index:1;
  position: relative;
}

/* .bar-bar-joema::after{
  content:"";
  height: 103%;
  width: 100%;
  position: absolute;
  top: -1px !important;
  z-index:0;
  background-size: contain;
  background-repeat: no-repeat;
  background-size: 100%;
  opacity: 0.50;
  filter: blur(130px);
  overflow: hidden;
}  */

.Bar-bar-joema-float{
  position: fixed;
  z-index: 5;
  background:transparent;
  width:100%;
  height: auto;
  display: grid;
  align-items: center;
  text-align: center;
  justify-content: center;
  overflow: hidden;
  box-shadow: 0px 4px 3px #0000006f;
  border-bottom: 1px solid #fff3;
  cursor: pointer;
  backdrop-filter: blur(80px);
  margin-bottom: 40px 0px;
}
/* 
.Bar-bar-joema-float::after{
  content:"";
  height: 100%;
  width: 100%;
  background: url(../imagenes/background/DDD.jpg);
  position: absolute;
  top: 0;
  z-index:2;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 150%;
  opacity: 0.20;
  filter: blur(0px) saturate(5%) hue-rotate(-20deg);
  overflow: hidden;
  animation: anim 3960ms ease-in-out 0ms infinite alternate-reverse !important;

}  */



@keyframes anim {
  0%{
    
    
    transform: translateY(0px);
      transition: all 250ms ease;
      opacity: 0.05;
      
  }
  100% {
    transform: translateY(1px);
    opacity: 0.55;
    
      transition: all 250ms ease;
  }
}
.box-images-joema div {
  
  border-radius: 10px;
  width: 100% !important;
  height: 100% !important;
} 

.box-img-1-joema {
  display: grid;
  grid-template-columns: 100%;
  width: 100%;
  position: relative;
  
}

.box-img-1-joema div {
  width: 100% !important;
  height: 100% !important;
  background-position: 50% 50% !important;
  background-size:cover !important;
} 

.box-img-2-joema {
  display: grid;
  grid-template-columns: 49% 49%;

position: relative;
  height: 100%;
  position: relative !important;
  width: 100% !important;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.box-img-2-joema div {
  width: 100% !important;
  margin: 0 auto !important;
  height: 100% !important;
  background-position: 50% 50% !important;
  background-size:cover !important;
} 



.bg-ground::after{
  content:"";
  height: 100%;
  width: 100%;
  background: url(../imagenes/background/DDD.jpg);
  position: absolute;
  top: 0;
  z-index:-1;
  background-size: contain;
  background-repeat: no-repeat;
  background-size: 100%;
  opacity: 0.30;
  filter: blur(230px);
  border: 1px solid #fff;

} 


 

/* ///////// la nueva welta ///// */

.windows-selection-hola{
  display: grid;
  background: #000;
  height: 30%;
  width: 100%;
  bottom: 0px;
  position: absolute;
  border-radius: 20px 20px 0px 0px  !important;
  grid-template-columns: 100%;

} 

.airbox-joema {
  padding-top: 10px;
  width: 70%;
  grid-template-columns: 25% 25% 25% 25%;
  display: grid;
  margin:50px auto;

}

.airbox-joema p {
  color: #535353;
  padding: 4px 0px;
  cursor: pointer;
  transition: all 200ms ease;
  font-size: 15px;
}

.airbox-joema h2 {
  color: #fff;
  padding: 4px 0px;
  cursor: pointer;
  transition: all 200ms ease;
}

.airbox-joema p:hover {
  color:  #e7ce7e;
  transition: all 200ms ease;
}

.windows-selection-help {
  display: grid;
  background: transparent;
  position: relative;
  height: 100%;
  width: 100%;
} 

.windows-selection-hola-hola {
  display: grid;
  position: absolute;
  left: 15px;
  top: 15px;
  cursor: pointer;
  opacity: 0.60;
  transition: all 200ms ease;
  width: 100%;
  padding-bottom: 10px;
  border-bottom:1px solid #51482b;
}



.img-fix-x {
  height: 20px;
  width: 20px;
  opacity: 0.60;
  transition: all 200ms ease;
}

.img-fix-x:hover {
  transition: all 200ms ease;
  opacity: 1;
}


.windows-selection-33 {
  display: grid !important;
  height: 100vh !important;
  width: 100% !important;
  background: transparent !important;
  position: absolute !important;
  
  background:linear-gradient(to bottom, #000000e5, #060606) !important;
  top: 0;
  left: 0;
}


.windows-selection-33::after{
  content:"";
  height: 100%;
  width: 100%;
 background: url(../imagenes/background/DDD.jpg); 
  position: absolute;
  top: 0;
  z-index:-1;
  background-size: contain;
  background-repeat: no-repeat;
  background-size: 100%;
  opacity: 0.10;
  filter: blur(130px);


} 

.box-header-general {
  display: grid;
  height: auto;
  min-height: 67px;
  max-width: 1100px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  border-radius:10px;
  grid-template-columns: 100%;
  
  column-gap: 0px;
  align-items: center;
  

} 


.icon-card-yeah {
  display: flex;
} 

.icon-normal-size {
  height: 34px;
  width: 34px;
  display: block;
} 

.icon-normal-size-2 {
  height: 21px;
  width: 21px;
  display: block;
} 


.icon-card-yeah-xx {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  padding: 14px 0px;
} 

.icon-card-yeah-xx img {
  opacity: 0.60;
}

.icon-card-yeah-xx h3 {
  color: #fff;
  padding-bottom:5px;
}

.pl-2 {
  padding-left: 2%;
}

.box-header-general-extra {
  padding: 32px 0px;
  border-bottom: 1px solid #ffffff6e;
  border-top:1px solid  #ffffff63;
  border-radius: 0px;
}

.windows-selection-hola-read{
  display: grid;
  background: #000;
  height: 100%;
  width: 100%;
  bottom: 0px !important;
  position: relative;
  border-radius: 20px 20px 0px 0px  !important;
  grid-template-columns: 100%;
  margin-top: 130px;
  

} 

.fitfix {
  background: linear-gradient( to top, #000, #00000000)!important;
  border-top:1px solid #ffffff30 !important;
  border-radius: 2px !important;
  height: auto !important;
  box-shadow: -1px -1px 10px #000000d9;
}


.edit-air {
  padding-bottom: 20px;
  border-radius: 0px;
  border-bottom:1px solid  #ffffff3f;
}


.edit-air h3 {
  color: #fff;
  font-size:2.2rem;
  font-weight: 500;
}

.edit-air p {
  font-size:19px;
  line-height: 22px;

}

@media screen and (min-width:0px) and (max-width:992px) {


  .head-bienes h2 {
    font-size: 1.5rem;
    color: #fff;
  }
  .box-images-joema {
    width: 90% !important;
  }

  .box-img-1-joema {
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    position: relative;
 
  }
}


.edit-air button {
  display: block;
  border-style: none;
  transition: all 200ms ease;
  border:1px solid #ffffff47;
  background:transparent;
  margin: 20px 0px;
  padding: 17px;
  color: #fff;
  opacity: 0.70;
  cursor: pointer;
  background:#ffffff1c;
}

.edit-air button:hover {
  transition: all 200ms ease;
  border:1px solid #fff;
} 


.edit-air-services h3 {
  color: #fff;
  font-size:2rem;
  font-weight: 500;
}

.edit-air-services p {
  font-size:19px;
  line-height: 22px;

}

.edit-air-services button {
  display: block;
  border-style: none;
  transition: all 200ms ease;
  border:1px solid #ffffff47;
  background:transparent;
  margin: 20px 0px;
  padding: 17px;
  color: #fff;
  opacity: 0.70;
  cursor: pointer;
  background:#ffffff1c;
}

.edit-air-services button:hover {
  transition: all 200ms ease;
  border:1px solid #fff;
}  

.grid-out{
  display: grid;
  grid-template-columns: 100%;
  text-align: center;
} 

.just {
  justify-content: center;
} 

.box-information {
  
  display: grid;
  position: relative;
  min-height: auto;
  width: 100%;
  padding-top: 100px;
} 

.world-box {
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 9999;
}

.world-select {
    background: #000;
    height: 85vh !important;
    max-height: 875px !important;
    width: 90%;
    border-radius: 20px;
    max-width: 1100px;
    margin: auto auto;
    z-index: 99999999;
    position: relative;
    /* border: 1px solid #3c3c3c; */
    box-shadow: 0px 0px 15px #222;
    overflow: hidden;
    transition:  all 200ms ease;
    padding: 24px 0px;   
}
.world-select:hover {
  /* border: 1px solid #626262; */
  transition: all 200ms ease;
}

.bg-click {
  position: absolute;
  width: 100%;
  height:100%;
  display: grid;
  z-index: 0;
}

.closet-world {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background: #000000aa;  
}

.cursor-pointer {
  cursor: pointer;
}

.font-perfil {
  font-weight: 600;
  color: #fff;
  font-size: 18px !important;
  letter-spacing: 0.5px !important;
}

.font-perfil span {
  font-weight: 300 !important;
  color: #ffffff83;
}
.subline {
  border-bottom: 1px solid #fff;
  cursor: pointer;
  transition:all 200ms ease;
}

.subline:hover {
  transition: all 200ms ease;
  color: #fff;
}

.font-perfil-t {
  font-weight: 600;
  color: #fff;
  font-size: 33px !important;
  padding-bottom: 7px;
}

.my-5 {
  margin: 35px 0px !important;
}

.box-option-account-perfil {
  display: flex;
  border: 1px solid #ffffff17;
  min-width: 300px;
  width: 100%;
  height: 175px;
  border-radius: 12px;
  box-shadow: 2px 2px 4px #000;
  position: relative;
  transition: all 200ms ease;
} 

.box-box-box-box {
  display: grid;
  grid-template-columns: 100%;
  width: 100%;
  color: #fff;
  padding: 10px 20px;
}

.box-box-box-box h2 {
  font-size: 19px;
  font-weight: 700;
  display: grid;
  align-items: center;
}
.box-box-box-box p {
  font-size: 15px;
  font-weight: 500;
  color: #ffffff5e;
}

.box-box-box-box img {
 margin-bottom: 15px;
 opacity: 0.60;
}

.box-option-account-perfil:hover{
  transition: all 200ms ease;
  border: 1px solid #acacac;
  cursor: pointer;
}

.magico-perfil {
  display: grid;
  grid-template-columns: 33% 34% 33%;
  row-gap: 20px;
  column-gap: 20px;
  justify-content: center;
} 

.desact-acc{
  text-align: center;
  display: flex;
  align-self: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap !important;
} 

.desact-acc span {
  border-bottom:1px solid #fff;
  color: #ffffffb5;
  font-size: 15px;
  cursor: pointer;
}

.desact-acc p{
  color: #fff;
} 

.bg-ground-y {
  overflow: auto!important;
}

/* ////////// bg super //// */

/* .bg-ground-y::after{
  content:"";
  height: 100%;
  width: 100%;
  background:linear-gradient(to bottom,#000, #1a1a1a), url(../imagenes/background/DDD.jpg);
  position: absolute;
  top: 0;
  z-index:-1;
  background-size: contain;
  background-repeat: no-repeat;
  background-size: 100%;
  opacity: 0.90;
  transform: scale(4.50);
  filter: blur(2px) hue-rotate(30deg);
  border: 1px solid #fff;
  border-radius: 11px;
  transition: all 200ms ease;

}  */

.footbar-statik section {
  background: #080808de;
  width: 100%;
  height: 50px;
  position: relative !important;
  bottom: 0;
  border-top: 1px solid #212121;
  padding-left: 0px !important;
  padding-right: 0px !important;
  z-index: 3;
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  justify-content: space-between;

}

.ppprofile {
  display: grid;
  grid-template-columns: 45% 55%;
  width: 100%;
}


.box1box1 {
  border: 1px solid #ffffff17;
  position: relative;
  padding: 0px 30px;
  transition: all 200ms ease;
} 

.box1box1:hover {
  border: 1px solid #ffffff60;
  transition: all 200ms ease;
} 

.box1box1 h2 {
  font-size: 25px !important;
}
.box1box1 p {
  font-size: 18px !important;
  color: #ffffff75;
  padding-bottom: 30px;
  font-weight: 400 !important;
}

.profileman {
  text-align: center !important;
  justify-content: center !important;
  position: relative;

}

.profileman div {
  height: 100px !important;
  width: 100px !important;
  background: #000;
  margin-left: auto !important;
  margin-right: auto;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mauto {
  height: 50%;
  width: 50%;
  display: grid;
  align-items: center;
}

.font-mm {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: #ffffff5e;
} 

.barbarraya:hover{
  transition: all 200ms ease;
  background: #fff;
}
.barbarraya {
  transition: all 200ms ease;
  width: 100%;
  height: 1px;
  background: #ffffff49;
  position: relative;
  display: block;
  margin-bottom: 20px !important;
} 

.newnewnew {
  width: 100%;
  max-width: 700px;
  position: relative;
  background: #1a1a1a;
  color: #fff;
  margin: 0 auto !important;
  display: grid;
  grid-template-columns: 33% 34% 33%;
  height: 60px;
  border-radius: 100px !important;
  align-items: center;
  text-align: center;
  border: 1px solid #473f2550;
  justify-content: center;
  z-index: 0;
  overflow: hidden !important;
}

.box-maestro-search {
  background: #04040469;
  height: auto;
  padding: 20px 0px ;
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(30px);
  width: 100% !important;
  /* border-bottom: 1px solid #0000005f; */
}

.bg-trans {
  display: grid !important;
  
  width: 100% !important;
  background: transparent !important;
  position: absolute !important;
  background:  linear-gradient( to bottom, #78777718 , rgb(22, 22, 22) )!important;
  top: 0;
  left: 0;
}
.bg-trans-fix {
  display: grid !important;

  width: 100% !important;
  background: transparent !important;
  position: absolute !important;
  align-items: start;

  top: 0;
  left: 0;
}
.newnewnew-head {
  width: 100%;
  max-width: 500px;
  position: relative;
  color: #fff;
  margin: 0 auto !important;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  height: 60px;
  border-radius: 100px;
  align-items: center;
  text-align: center;
}

.newnewnew-head div {
  opacity: 0.60;
  cursor: pointer;
}

.newnewnew-head div:hover {
  opacity: 1;
  cursor: pointer;
}

.active-alquiler {
  border-bottom: 1px solid #e7ce7e;
  padding-bottom: 10px ;
  opacity: 1 !important;
} 

.box-siguiente-brutal {
  height: 500px;
  max-width: 800px;
  width: 100%;
  background:#0000002f;
  backdrop-filter: blur(300px);
  margin: 5px auto !important;
  border-radius: 30px;
  /* box-shadow: -1px 2px 20px #000; */
  z-index: 222;
  position: relative;
  /* border:1px solid #ffffff1c; */
  display: grid;
  /* grid-template-columns: 40% 60%; */
  padding: 3rem 1.4rem;
  transition: all 200ms ease;
  grid-template-columns:  auto auto;
} 
.box-siguiente-bruta:hover {
  transition: all 200ms ease;
  margin: 5px auto !important;
}

.combaattt {
  border-radius: 100px !important;
}

@media screen and (max-width:992px) {
  .box-siguiente-brutal {
    grid-template-columns: auto;
    height: auto;
    max-width: 100%;

  }
  

  .bx-s-brutal{
    grid-template-columns: auto !important;
    
  }

  .box-siguiente-brutal {
    padding: 1rem 0px  !important;
    margin:5px 0px !important;
    max-width: 95% !important; 
    margin: 4px auto !important;
  }

  
  .newnewnew {
    height: 40px !important;
  }
.ss-button button {
  border: 1px solid #fff;
  height: 40px !important;
  width: 100%;
  border-radius: 100px;
  border-style: none !important;
  background: transparent;
  color: #fff;
  box-shadow: 0px 0px 4px #000;
  cursor: pointer;
  transition: all 400ms ease;
  font-size: 15px;
  font-weight: 400;
  opacity: 0.20;
} 

.box-maestro-search {
  padding: 0px 0px !important;
  background: #04040469;
  height: auto;
  padding: 20px 0px ;
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(30px);
}

.newnewnew-head{
  height:  auto !important;
  padding: 10px 0px  !important;
  grid-template-columns: 33% 33% 33% !important;
  font-size: 0.80rem !important;
  width: 92% !important;
} 

.sexy-border-right {
  border-right: 1px solid transparent !important;
}

.provincias {
  display: flex;
  min-width: 40% !important;
  width: 100%;
  height:100% !important;
  padding: 0px !important;
}
.newnewnew{
  grid-template-columns: 33% 33% 33% !important;
  font-size: 0.40rem !important;
  width: 92% !important;
  margin: 10px auto !important; 
}

.combo-lista {
  margin: 1px auto !important; 
}

.tttt {
  font-size: 16px;
  color: #fff;
  margin: 10px auto !important;
  padding-left: 20px;
  width: 100% !important;
}
.ss-button button{
  font-size: 0.80rem !important;
}
.active-alquiler {
  
  padding-bottom: 3px;

}

.goat-search {
  width: 100%;
  position: relative;
  display: grid;
}


.elmama span {
  
  color: #fff2c7;
  border-radius: 100px;
  font-size: 0.70 !important;
  text-transform: uppercase;
}
.hopehopehope {
  bottom:0px !important;
}



/* media end */

}


.goat-search {
  width: 100%;
  position: relative;
  display: grid;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

.bx-s-brutal {
  grid-template-columns: 50% 50%;
  transition: all 200ms ease;
}


.bx-s-10 {
  grid-template-columns: 100%;
  transition: all 200ms ease;
}


.sexy-border-right {
  border-right:1px solid #515151;
  height: 1fr;
  max-height: 150px !important;
  /* background: #000;  */
  overflow-y: scroll !important;

} 

.tttt {
  font-size: 16px;
  color: #fff;
  margin-bottom: 20px;
  padding-left: 20px;
} 

.combo-lista:hover {
  background: #4242424f;
  cursor: pointer;
}

.combo-lista {
  width: 100%;
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0px 20px;
  margin: 0px -10px;
  border-top:1px solid transparent;
  border-bottom:1px solid transparent;
}

.combo-lista h3 {
  color: #80785c;
  font-weight: 500;
  font-size: 17px;
}

.combo-lista img {
  width: 20px;
  height: 20px;
  padding: 15px;
  background:#242424;
  border-radius: 15px;
  margin: 10px 16px 10px 0px;
}


.hit-hit {
  display: grid;
  flex-wrap: nowrap;
  grid-template-columns: 33% 33% 33%;
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  height: 100%;
  grid-auto-flow: row;
  padding-bottom: 40px;
}



@media screen and (max-width:992px) {
  /* .hit-hit {
    display: flex;
    background: #000;
    align-items: center;
    overflow-x: scroll !important;
  }  */

  .bar-bar-joema {
    letter-spacing: 0px;
  }
  .hit-hit{
    display: flex;
     /* grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); Ajusta el ancho mínimo y máximo según tus necesidades */
    gap: 0px; /* Espacio entre elementos */
    /* overflow-x: scroll !important;  Permite el desplazamiento horizontal */
    padding: 10px;
    max-width: 100%; /* Ajusta el ancho máximo según tus necesidades */
   
    grid-auto-flow: column !important;
    overflow-x: scroll !important;
    overflow-y: hidden !important;

  
  }
  
  .box-busqueda-relative{
    grid-template-columns: 1fr !important;
  }



  .newnewnew {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.sscroll {
  overflow-y:hidden !important; 
  position: relative !important;

}

.provincias {
  display: flex;
  min-width: 30%;
  width: 100%;
  height: 155px;
  padding: 32px;
  border-radius: 12px;
  /* box-shadow: 2px 2px 4px #000; */
  position: relative;
  margin: 2px;
  justify-content: center;
  align-items: center;
  transition: all 200ms ease;

} 

.box-box-box {
  width: 100%;
  height: 175px;
  border: 1px solid #ffffff17;
} 

.elpapadelos {
  display: block;
} 

.elpapadelos h2 {
  text-align: center;
  font-size: 13px;
  margin-top: 10px;
 }

.icon-normal-size-30 {
  display: grid;
  height: 100px;
  width: 100px;
  margin: auto !important;
  border: 1px solid #ffffff1f;
  
  border-radius: 12px;
  transition: all 200ms ease;
  cursor: pointer;
}

.icon-normal-size-30:hover {
  transition: all 200ms ease;
  border: 1px solid  #e7ce7e;
} 

.box-information {
  position: relative !important;
}


.arc {
  display: grid;
  grid-template-columns: 20% 80% !important;
  position: relative;
  height: 100vh;
  background: #00000068
}

.arc-closet {
  display: grid;
  grid-template-columns: 20% 80% !important;

}

.mensaje-expand {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  position: relative;
}

/* 
/////////// aqui vamos a colocar la barra ajustada //////// */

.bar-bar-susano {
  border-bottom: 1px solid #ffffff3e;
  width:100%;
  height: 8vh;
  letter-spacing: 30px;
  text-align: center;
  align-items: center;
  display: grid;
  font-style: capitalize !important;
  position: relative;
  color: #fff;
}

.bar-bar-susano p {
  z-index:1;
  position: relative;
}

.bar-bar-susano::after{
  content:"";
  height: 103%;
  width: 100%;
  background:linear-gradient( to right, #00000075, #000);
  position: absolute;
  top: -1px !important;
  z-index:0;
  background-size: contain;
  background-repeat: no-repeat;
  background-size: 100%;
  opacity: 1;
} 



.mensaje-2 {
  border-left:1px solid #ffffff3e;
  border-right: 1px solid #ffffff3e;
  width: 75%;
  transition: all 200ms ease;
  position: relative;
} 

.mensaje-3 {
  position: relative;
  transition: all 200ms ease;
  width: 25%;
}

.mensaje-expand {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
.width-false {
  width: 0% !important;
  transition: all 200ms ease;
  opacity: 0;
 z-index: -1;
 position: relative;
}

.width-true-true {
  width: 100% !important;
}


.mensaje-1 {
  position: relative;
}

.box-cloud {
  display: grid;
  position: relative;

  color: #fff;
  background: transparent;
  border-top: 1px solid #ffffff3e;
  border-bottom: 1px solid #ffffff3e;
  width: 100% !important;
  height: 75px !important;
  margin-bottom: 20px !important;
}

.box-cloud-def{
  display: grid;
  width: 90% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  grid-template-columns: 40% 40%;
  position: relative;
  justify-content: space-between;
  align-items: center;
  height: auto!important;
  
}
.box-cloud-def p {
  font-size: 19px;
  font-weight: 700;
}
.box-cloud-body {
  display: grid;
  position: relative;

  color: #fff;
  background: transparent;
 text-align: center;
  width: 100% !important;
  height: auto !important;
}
.box-cloud-def-body{
  display: grid;
  width: 90% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  grid-template-columns: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  height: auto!important;
}

.box-cloud-def-body h3 {
  font-size: 19px;
  font-weight: 700;
  max-width: 100%;
  margin: 10px auto;
}

.box-cloud-def-body p {
  font-size: 17px;
  line-height:22px !important;
  font-weight: 400;
  color: #909090 !important; 
}

.box-cloud-def img {
  height: 16px;
  width: 18px;
  
}  

.img-arc {
  transition: all 200ms ease;
  padding: 14px;
  width: auto !important;
  height: auto !important;
  border-radius: 106px;
  margin-left: auto !important;
  cursor: pointer;
  background: transparent;
}

.img-arc:hover {
  transition: all 100ms ease;
  background: #b09e7b14 !important;
} 


.btn-bienes:hover {
  text-shadow: 0px 0px 5px #fff;
  transition: all 200ms ease;
  border: 1px solid #e7ce7e !important;
}
.btn-bienes {
  display: grid;
  background: linear-gradient(to right , #392d18, #b79149);
  border-style: none !important;
  width: 170px;
  border-radius: 10px;
  color: #ffffffe9;
  border: 1px solid #aa6e00 !important;
  padding: 13px 15px !important;
  cursor: pointer;
  position: relative;
  margin:10px auto;
  font-size: 18px;
}




.class-class-class-2{
  /* border-left:1px solid #ffedb270  !important;
  border-right:1px solid #ffedb270   !important; */
  transition: all 200ms ease;
  position: relative;
}



.class-class-class-1{

  /* border-right:1px solid #ffedb270   !important; */
  transition: all 200ms ease;
  position: relative;
}



.class-class-class-3{

  /* border-left:1px solid #ffedb270   !important; */
  position: relative;
  transition: all 200ms ease;
}

.class-class-class-1::after {
  content: "";
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 1px;
  background: #e7ce7e ;
}

.class-class-class-right::after {
  content: "";
  position: absolute;
  top:0;
  right:0;
  width: 1px;
  height: 100px;
  background: #e7ce7e;
}

.class-class-class-2::after {
  content: "";
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 1px;
  background: #e7ce7e ;
}

.class-class-class-3::after {
  content: "";
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 1px;
  background: #e7ce7e ;
} 




.hijo-hijo-hijo {
  display: grid;
  grid-template-columns: 100%;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 0;
  opacity: 0;
  transition: all 600ms ease;
} 

.opacity-true {
  opacity: 100;
  transition: all 800ms ease;
}

.hijo-right {
  width: 100%;
  position: absolute;
  width: 1px;
  height: 100%;
  background:  #e7cf7e;
  top:1px;
  left:0;
}

.hijo-center {
  width: 100%;
  height: 0.05rem;
  background: #e7cf7e;
  position: absolute;
  top:1px;
  left:0;
}

.hijo-left {
  width: 100%;
  position: absolute;
  width: 1px;
  height: 100%;
  background:  #e7cf7e;
  top:1px;
  right:0;
}


.favoritos-style {
  display: grid;
  height: auto;
  grid-template-columns: 100%;
  width: 80% !important;
  /* background: linear-gradient(to left, #0000, #00000047); */
  margin: 0px auto;
  border-bottom: 1px solid #ffffff14;
}

.favoritos-mensaje {
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  display: grid;
  text-align: left;
  height: auto;
}

.favoritos-mensaje h4 {
  color: #fff;
  font-size: 21px;
  margin-bottom: 20px;
}

.favoritos-mensaje h3 {
  color: #fff;
  font-size: 38px;
  margin-bottom: 40px;
}
.favoritos-mensaje p {
  color: #fff;
  font-size: 15px;
}



.willoff{ 
  animation: will-option-fx-out 960ms ease 0ms 1 normal !important;
}

@keyframes will-option-fx-out  {
  0%{
    
     transform: scale(1);
     opacity: 1;
      transition: all 250ms ease;
      z-index: 0;
  }
  100% {
      z-index: -1;
      transform: scale(0.90);
      opacity: 0;
      transition: all 250ms ease;
  }
}

.willon {
  animation: will-option-fx-in 360ms ease 0ms 1 normal !important;
}

@keyframes will-option-fx-in  {
  0%{
    
     transform: scale(0.90);
     opacity: 0;
      transition: all 250ms ease;
      z-index: 0;
  }
  100% {
      z-index: -1;
      transform: scale(1);
      opacity: 1;
      transition: all 200ms ease;
  }
} 


.box-world-world-body {
  display: block;
  grid-template-columns: 100%;
  margin-top: 25px;
  height: 100%;
  border-top:1px solid #353535;
  transition: all 200ms ease;
  background: transparent;
  overflow-y: scroll !important;
  box-shadow: 0px -10px 20px #000;
} 


.padding-world {
  padding: 0px 24px;
} 

.boddy-joema-world {
display: grid;
grid-template-columns: 100%;
width: 100%;
padding: 20px 0px;
}


.boddy-joema-world h5 {
  font-size: 16px;
  color: #929292;
  margin:0px !important;
  font-weight: 300;
  width: 100%;

}

.boddy-joema-world h4 {
  font-size: 17px;
  color:#fff;
  font-weight: 400;
  width: 100%;
} 


.boddy-joema-world h2 {
  font-size: 25px;
  color:#fff;
  font-weight: 700;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
} 



.mapping-world-content-individual {
  border: 1px solid transparent;
  background: transparent;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 7px;
  padding: 10px 10px;
  transition: all 200ms ease;
  margin: 12px;
} 

.mapping-world-content-individual:hover {
  transition: all 200ms ease;
  border: 1px solid #31313187;
  background: #31313133;
  cursor: pointer;
}

.map-active {
  border: 1px solid #e7ce7e;
  transition: all 200ms ease;
}

.map-noactive {
  border: 1px solid transparent ;
  transition: all 200ms ease;
}

.mapping-world-content-group {
  display: grid;
  grid-template-columns: repeat(5, 18.5%);
  column-gap: 1.5%;
  row-gap: 10px;
  padding: 20px 0px;
}

.prop-text-prop{
  transition: all 200ms ease;
  display: grid;
  position: relative;
  /* border-top: 1px solid #000; */
  padding: 10px 0px;
  border-radius: 9px;
  /* box-shadow: 0px -10px 24px #000; */
  padding-top: 10px;
  top:0px;
  /* background: #000000d7; */
  overflow: auto;
  
  
}

/* /// aqui vamos a colocar esl estilo de las provincias ////  */

.location-propiedades {
  display: grid;
  position: absolute;
  width: 100%;
  height:100%;
  bottom:0px;
  left:0px;

  z-index: 0;
  grid-template-columns: repeat(1, 100%);
  align-items: center;
  text-align: center;
  align-items: baseline;
  opacity: 1;

  transition: all 200ms ease;
  overflow: hidden !important;
  border-radius: 20px;
  z-index: 0;

}

.propiedades-box:hover .location-propiedades {
  opacity: 1;
  background: none;
  border-radius: 20px;
  transition: all 300ms ease;
  width: 100%;
}

.propiedades-box:hover .dist-title-corregimiento {
  transform: scale(1);
  transition: all 100ms ease-in-out;
  opacity: 0.60;
  
  text-shadow:  1px 3px 0px #ffffff82;
}

.slick-dots {
  z-index: 1;
}





.border-special {
  
  margin-top: auto;
  margin-bottom: auto;
  position: absolute;
  width: 100% ;
  display: grid;
  top: 0;
}

.dist-title-corregimiento {
  text-align: center;
  font-size: 37px;
  line-height: 26px;
  width:100%;
  margin-left: auto;
  margin-right: auto;
  text-shadow:  1px 3px 0px #e7cf7e;
  font-weight: 800;
  color: #ffffff15;
  transform: scale(0.90);
  transition: all 100ms ease-in-out;
  margin-bottom: 20px ;
  padding-bottom: 20px;
  opacity: 0;
  position: absolute;
  top:200px;
} 




.dis-corregimiento {
 position: absolute;
 top:0px;
  width: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
  margin: 0px 0px; 
  text-align: center;
  
  background: linear-gradient(to bottom, #0000004d, #0000);
  padding: 0px 0px;
  /* box-shadow: 0px 1px 2px #000; */
  border-radius: 20px;
  transform: scale(1);
  text-transform: uppercase !important;
  letter-spacing: 1px;
  height: 80px;
  justify-content: center;
  display: grid !important;
  align-items: center;
  opacity: 1;
  display: grid;
  grid-template-columns: repeat(1, 80%);
  justify-content: center;
} 

.propiedades-box:hover .dis-corregimiento {
   opacity: 1;
  transition: all 100ms ease;

}
.propiedades-box:hover .dis-corregimiento h2 {
  font-size: 12px !important;
  transition: all 350ms ease;
  filter: blur(0px);
  transform: scale(1.0);
  opacity: 1;
}

.dis-corregimiento h2 {
  font-size: 12px !important;
  filter: blur(40px);
  transition: all 900ms ease;
  transform:scale(1);
  opacity: 1;
}

.good-raya {
  color: #e7ce7e !important;
} 

@media screen and (min-width:0px) and (max-width:992px) {
  .dis-corregimiento h2 {
    font-size: 12px !important;
    transition: all 350ms ease;
    filter: blur(0px);
    transform: scale(1.0);
    opacity: 1;
  }
  .dist-title-corregimiento {
    font-size: 37px;
    line-height: 26px;
    width:100%;
    margin-left: auto;
    margin-right: auto;
    text-shadow:  1px 3px 0px #ffffff82;
    font-weight: 800;
    color: #ffffff15;
    transform: scale(1);
    transition: all 100ms ease-in-out;
    margin-bottom: 20px ;
    padding-bottom: 20px;
    opacity: 0.60;
  
  
  }
  
  .prop-text-prop {
  transition: all 200ms ease;
  background: #000000 !important;
  box-shadow: 0px -2px 2px #000 ,0px -1px 30px #000;
  top:0px;
} 

.propiedades-box p {
  transition: all 150ms ease;
  color: #e7cf7e !important;
}



}


.ss-button button {
  border: 1px solid #fff;
  height: 60px;
  width: 100%;
  border-radius: 100px;
  border-style: none !important;
  background: transparent;
  color: #fff;
  box-shadow: 0px 0px 4px #000;
  cursor: pointer;
  transition: all 400ms ease;
  font-size: 15px;
  font-weight: 400;
  opacity: 0.20;
} 

.ss-button {
  background: #05050574;
  width: 100%;
}
.ss-button button:hover {
  transition: all 200ms ease;
  background: #0505057e;

}

.metal-trans {
  transition: all 500ms ease;
}

.blurenia {
  filter: blur(30px) !important;
  transition: all 700ms ease;
  transform: scale(1);
  opacity: 0;
}

.mascara-primo {
  opacity: 1;
  transition: all 700ms ease;
}

.Active-btn-ss button {
  opacity: 1;
  transition: all 100ms ease;
  height: 60px;
  background: transparent;
  z-index: 99;
  display: block;
  position: relative;
  color: #fff;
  box-shadow: 0px 0px 30px #313131;
  border: 1px solid #ffefbc !important;

} 

.opaopacero {
  opacity: 0;
  transition: all 100ms ease;
}


.hit-hit-2 {
  display: grid;
  flex-wrap: nowrap;
  grid-template-columns: 100%;
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
  grid-auto-flow: row;
} 

.sscroll-2 {
  overflow-y: scroll !important;
  position: relative !important;
  height: 100%;
  padding-bottom: 60px;
} 

.sscroll{
  overflow-x: hidden !important;
} 

.wenwen {
  /* border: 1px solid #e7ce7e; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
} 


.provincias-2 {
  display: flex;
  min-width: 30%;
  width: 100%;
  height: 265px;
  padding: 32px;
  border-radius: 12px;
  /* box-shadow: 2px 2px 4px #000; */
  position: relative;
  margin: 2px;
  justify-content: center;
  align-items: center;
  transition: all 200ms ease;
}



.icon-normal-size-302 {
  display: grid;
  height: 190px;
  width: 190px;
  margin: auto !important;
  border: 1px solid #e7ce7e29;
  border-radius: 12px;
  transition: all 200ms ease;
  cursor: pointer;
  box-shadow: -4px 5px 4px #000;
background: #030304;
} 

.mari-mari img {
  border: 1px solid #e7ce7e !important;
  box-shadow: 0px 0px 161px #ffffff44;
}


.mangek span {
  color: #fff;
  padding-left: 5px;
} 

.mangek:hover span {
  color:  rgb(255, 244, 207) !important;
  text-shadow: 0px 0px 5px transparent;
} 

.mangek:hover img {
  border: 1px solid #e7ce7e !important;
  box-shadow: 1px 1px 4px transparent !important;
}

.mangek img {
  border: 1px solid transparent !important;
  box-shadow: 1px 1px 4px transparent !important;
}


.single-line {
  white-space: nowrap !important;
}

.single-box {
  display: grid;
  grid-template-columns: 100%;
  width: 100%;
 
  
}

.single-line {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.papitolindo {
  color: #2f2f2d;
  background: #0000007f;
  border-bottom: 1px solid #e7ce7e;
  border-top: 1px solid #e7ce7e ;
 
}  
.willonfx {
  animation: horasiddd 2360ms ease-in-out 0ms infinite alternate !important;
}

.bombaso-bbb {
  animation: horasiddd-text 560ms ease-in-out 0ms infinite alternate !important;
}

.willonfx-text {
  animation: horasiddd-text 560ms ease-in-out 0ms infinite alternate !important;
}

@keyframes horasiddd-text {
  0% {
    color:#f7e6ad;
  } 
  100% {
    color:#bcac79;
  }
}


.papitolindo:hover {
  color: #2f2f2d;
  background: #0000007f;
} 


.papitolindo img {
  background: #000;
  border: 1px solid #e7ce7e !important;
} 

.papitolindo span {
  
  color: rgb(255, 244, 207)  !important;
} 

.microspan {
  margin: 0px 0px;
  color: #fff2c7;
  font-weight: 300;
  
}


.candela-closet {
  display: grid;
  position: absolute;
  width: 100%;
  height: 100vh;
  top:0;
  left:0;
  background: #00000069;
  backdrop-filter: blur(5px);
  /* background: linear-gradient(to bottom , #000,#000000d4, #000) !important */
}

.loader-joema-2023 {
  height: 1.8rem;
  width: 1.8rem;
}

.Loader-style-bottom {
  position: absolute;
  bottom:14px;
  right: 0px;
  margin: 10px;
  transition: all 100ms ease;
}

.centro-delantero { 
  height: 100%;
  width: 100%;
  background: #000;
  display: grid;
  grid-template-columns: 100%;
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: absolute;
  /* animation: muevelo 1300ms ease 0ms 1 alternate; */
} 

@keyframes muevelo {
  0%{
    opacity: 0;
  }

  100%{
    opacity: 1;
  }
}

.centro-delantero div {
  margin: 0px auto;
}

.bottom-information {
  display: grid;
  position: relative;
  width: auto;
  max-width: 100%;
  text-transform: uppercase;
  letter-spacing: 0px;
  padding:5px 0px;
  font-size: 10px !important;
  
}




.elmama {
  position: absolute;
  width: 100%;
  z-index: 99999;
  bottom:-2px;
  opacity: 0;
}


.hopehopehope {
  opacity: 0.40;
  transition: all 100ms ease;
  bottom:6px;
  z-index: 0;
}
.hopehopehopeno {
  bottom:-2px;
  transition: all 200ms ease;
  opacity: 0 !important;
}

.elmama span {
  
  padding: 8px 20px;
  color: #fff2c7;
  border-radius: 100px;
  font-size: 10px;
  text-transform: uppercase;
}

.ss-button {
  position: relative !important;
}

.maricon-jones{
  display: grid;
  grid-template-columns: repeat(3, auto);
  text-align: center;
  background: transparent;
  width: 100% !important;
   margin: 0 auto;
} 

/* .box-jones-maricon {
  display: flex;
  border: 1px solid #000;
  width: 700px;
  height: 30px;
} */


.joema-container-acc {
  display: grid;
  grid-template-columns: 100%;
  border: 1px solid #fff;
} 


.Hola-mi-amor{
  display: grid;
  grid-template-columns: 100%;
  position: relative;
  height: 76vh;
  overflow-y:none;
  width:58%;
  padding: 50px 0px;
  margin: 0px auto;
  /* border:1px solid #fff; */

}

.Grande-grande-es-el-Senhor {
  display: block;
  width: 100%;
  position: relative;

}
.header-joema-hola-title p{
  color: #eaeaea;
  font-weight: 600;
  margin:25px 0px;
  font-size: 2.2rem;
}
.information-input{

  height: auto !important;
 
}
.contenedor-input{
  padding-bottom: 22px;
  padding-top: 22px;
  border-bottom: 1px solid #111;
  height:auto !important;
  transition: all 100ms ease-in-out;
  background: transparent;

}
.contenedor-header-input {
 
  display: flex;
  flex-wrap: nowrap;
  height: 25px !important;
  justify-content: center;
  align-items: center;
}   
.contenedor-header-input h2 {
margin-right: auto;
font-size: 17px;
color: #fff;
}
.contenedor-header-input button {
  margin-left: auto;
  border-style: none;
  background: transparent;
  color:#fff;
  font-size: 15px;
  text-decoration:underline;
  cursor: pointer;
}
.input-joema-master-geo-contenedor {
height: auto !important;
grid-template-columns: 49% 49%;
column-gap: 2%;
justify-content: center;
display: grid;
padding: 20px 0px;
animation:inyeah 500ms ease-in-out 0s 1 normal;
transition: all 200ms ease;
position: relative;
}

.pad-00-y {
  padding: 0px 0px !important;
}
.pad-01-y {
  padding: 5px 0px !important;
}
.input-contenedor-100 {
  grid-template-columns: 100% !important;
}


.input-contenedor-370 {
  grid-template-columns: 43% 57% !important;
  column-gap: 0% !important;
}

.input-contenedor-50 {
  grid-template-columns: 50% 50% !important;
  column-gap: 0% !important;
}

@keyframes inyeah {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}


@keyframes inyeah2 {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}


@keyframes inyeah3 {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

@keyframes inyeah4 {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}




.border-bottom-active{
  border-bottom: 1px solid transparent;
  animation:inyeah3 200ms ease-in-out 0s 1 normal;
  box-shadow:-2px 5px 20px #00000091, 1px 10px 14px #7c7c7c63;
  padding: 10px 10px 50px 20px;
  border-radius: 9px;
  z-index: 2;
position: relative;
top:0px;
  /* background: #2d2c2c1c; */
}
.input-joema-master-geo {
  border: 1px solid #ffffff29;
  border-radius: 8px;
  height: 56px !important;
  display: grid !important; 
  grid-template-columns: 100% !important;
  padding: 7px !important;
  position: relative !important;
  margin: 10px;
}
.text-input{
  width: 90%;
  line-height: 20px;
  animation:inyeah 900ms ease-in-out 0s 1 normal;
}
.text-input-2 {
  width: 90%;
  line-height: 20px;
  animation:inyeah2 900ms ease-in-out 0s 1 normal;
}

.input-joema-master-geo label {
  border-style: none;
  background: transparent;
  height: auto !important;
}
.input-joema-master-geo input {
  border-style: none;
  background: transparent;
  height: auto !important;
  color: #fff;
  font-size: 17px !important;
  z-index: 1;
}

.input-joema-master-geo select {
  border-style: none;
  background: #1f1c1b;
  height: auto !important;
  color: #fff;
  font-size: 17px !important;
  z-index: 1;
  cursor: pointer;
  opacity: 1;
  border: none;
  
}
.select-sty {
  background: #1f1c1b;
  transition: all 200ms ease-in-out;
}

.font-clarity {
  color: #fff;
  font-size: 14px;
  padding: 10px 0px;
}

.input-joema-master-geo option {
  border-style: none !important;
  background: #1a1311;
  height: auto !important;
  color: #fff;
  border: none !important;

}
.header-joema-hola-acc span{
  margin:0px 15px;
}
.header-joema-hola-acc {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  color: #727272;
}
.contenedor-doble {
  display: grid;
  grid-template-columns: 60% 40%;
}

.box-barra-modal {
  position: relative;
  display:grid;
  width: 100%;
  height: 100%;
}
.BarraModalBack {
  display: grid;
  grid-template-columns: 100%;
  width: 100%;
  position: absolute;
  height: auto;
  z-index: 9999999999;
  top: 0;
  
}


.BarraModalBack h2 {
  color: #fff !important;
}

.head-e-77 {
  height: auto;
  padding:7px;
  display: flex;
align-items: center;
text-align: right;
color: #000 !important;
justify-content: center;
background: linear-gradient( to right, #0f0f0f, #383838c7);
backdrop-filter: blur(30px);
  
}

@media screen and (min-width:0px) and (max-width:992px) {
 
 .contenedor-doble {
  grid-template-columns: 100%;
 } 
 .contenedor-action-bar {
  width: 100% !important;
 }
 .information-box-box-container{
  width: 100% !important;
  margin-top: 10px;
 }
 .magico-perfil {
  grid-template-columns: 50% 50% !important;
  row-gap: 13px;
  column-gap: 12px;
 }
 .airbox-joema{
  grid-template-columns: 100% !important;
 }
 .favoritos-mensaje {
  width: 100%;
 }
 .arc {
  display: grid;
  grid-template-columns: 100% !important;
 }
.box-box-box-box {
  display: grid;
  grid-template-columns: 100%;
  width: 90%;
  color: #fff;
  padding: 10px 10px;
  margin: 0 auto;
}
.mapping-world-content-group {
  grid-template-columns: 100%;
}

.box-option-account-perfil {
  height: 250px;
}
.box-box-box-box p {
  font-size: 0.8rem;
}
.box-box-box-box h2{
  font-size: 1.19rem;
}

}

.tittle-space {
 font-size: 20px;
 color: #fff;
 padding: 40px 0px 15px 0px;


 transition: all 180ms ease-in-out;
 animation: vengala 1250ms ease-in-out 0ms 1 alternate;
}

.tittle-space h2 {
  text-shadow: 10px 0px 30px #e7cf7e23;
}

.tittle-space p {
  font-size: 17px;
  padding: 40px 0px 15px 0px;
  transition: all 180ms ease-in-out;
  color: #fff;
  animation: vengala 1250ms ease-in-out 0ms 1 alternate;
 }


@keyframes vengala {
  from{
    opacity: 0;
  }
  to  {
    opacity: 1;
  }
}
.elmentaohijo{
  border-bottom: 1px solid #111;
  margin-bottom: 25px;
  padding-bottom: 25px;
}
.elmentaohijo h3 {
  padding: 20px 0px;
  font-size: 22px;
  color: #fff;
}

.elmentaohijo p {
  padding: 20px 0px;
  font-size: 16px;
  line-height: 21px;
  color: #706f70;
}

.elmentaohijo img {
 height: 50px;
 width: 50px;
}
.sabes-que-te-quiero {
  padding-top: 20px;
}
.information-box-box-container{
  border: 1px solid #111;
  height: auto;
  width: 80%;
  margin-left: auto;
  border-radius: 9px;
  padding: 20px;
}
.Font-Love-Title {
  font-size: 32px;
}
.joema-btn-special{
  background: #424242;
  border-radius: 9px;
  font-size: 15px;
  color: #fff;
  padding: 12px 33px;
  border-style: none;
  cursor: pointer;
}
.btn-container {
  height: auto !important;
}

.w-100 {
  width: 100% !important;
}
.border-board {
  border: 1px solid #fff;
} 

.preset-size {
  width: 100px;
  height: 100px;
}

.prueba1 {
  background: #f1209f !important;
  height: 50px !important; 
  width: 40px !important;
}
.prueba2 {
  background: #2082f1;
  height: 200px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 25%);
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  height: 150px;
}
.prueba3 {
  background: #00b570;
  grid-template-columns: repeat(1, 100%);
  height: 400px;
}

.aqui-en-el-centro-mi-vida {
  display: grid;
  grid-template-columns: repeat(2, 50%);
}

.hw-joema-20 {
  height: 20% !important;
  background: #000;
}

.hw-joema-80 {
  height: 80% !important;
  background: #2a2a2a;
}

.joema-joema-card{
  height: 100%;
  background: #fff;
  width: 100%;
}

/* .prueba0 {
  background: #9d1717 !important;
} */

.font-style-maleante {
  font-size: 20px;
}

.cornudo {
  border: 1px solid #000;
} 

.active-joema{
  color: #fff !important;
  text-shadow: 10px 0px 30px #e7cf7e75 ;
}

.focus-active {
  color: #e7ce7e !important;
  transition: all 550ms ease;
  box-shadow: -1px 3px 10px #0000006c;
  opacity: 1;
}


.focus-active label {
  font-size: 14px !important;
  position: relative !important;
  transition: all 200ms ease-in-out;
  top:0 !important;
  animation:inyeah4 600ms ease-in-out 0s 1 normal;
  left:0px !important;
  
}

.focus-active input {
  position: relative;
  transition: all 200ms ease-in-out;
  font-size: 15px !important;
  letter-spacing: 1px;
  padding: 0px 0px !important;
  opacity: 1 !important;
  
}
.focus-active select {
  opacity: 1 !important;
  
}

.input-hide-hide label {
  transition: all 200ms ease-in-out;
  font-size: 19px;
  position: absolute;
  top:15px;
  left:9px;
 
}
.input-hide-hide input {
  opacity: 0;
  transition: all 200ms ease-in-out;
  padding: 5px 0px;
}


.Loader-Joema-central {
  display: grid;
  grid-template-columns: 100%;
  height: 100vh;
  width: 100%;
  background: #000000c6;
  justify-content: center;
  align-items: center;
}

.Loader-Joema-central div {
  margin-left: auto !important;
  margin-right: auto !important;
  /* box-shadow: 0px 0px 124px #ffffff46; */
  border-radius: 100px;
 
  padding: 2px;
} 

.contenedor-action-bar {
  display: grid;
  grid-template-columns: repeat(3, auto);
  width: 80%;
  text-align: center;
  justify-content: left;
} 


.clasic-name-btn {
  border-style: none;
  border-bottom:1px solid transparent;
  background:transparent;
  padding: 20px 10px;
  color: #e9e9e9;
  cursor: pointer;
  font-size: 17px;
  letter-spacing: 0px;
  /* white-space: nowrap; */
  opacity: 0.50;
} 


.clasic-name-btn-active {
  border-bottom:1px solid #e7ce7e;
  transition: all 100ms ease-in-out;
  color: #fff;
  opacity: 100;
} 

.master-contenedor-action-bar {
  width: 100%;
  background: transparent;
  /* border-bottom: 1px solid #00000053; */
  height: 100%;
display: block;
position: relative;
} 

.bombaso {
  border-top: 1px solid #00000053;
}

.text-deco:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #fff;
  transition: all 100ms ease-in-out;
}

.text-deco {
  transition: all 200ms ease-in-out;
}


.set-style {
  margin-bottom:50px !important;
  padding-top: 40px;
}

.kill-padding {
padding: 0px !important;
padding-top: 0px !important;
}


.set-style button {
  color: #fff;
  margin-top:15px;
}
.set-style h2 {
  color: #fff;
  font-size: 22px;
}

.set-style p {
  color: #706f70;
  margin:20px 0px;
  font-size: 17px;
} 
.set-style-2 {
  margin-bottom:0px !important;
  padding-top: 15px;
}

.set-style-2 button {
  color: #fff;
  margin-top:15px;
}
.set-style-2 h2 {
  color: #fff;
  font-size: 22px;
}

.set-style-2 p {
  color: #706f70;
  margin:10px 0px;
  font-size: 17px;
} 

.border-top {
  border-top: 1px solid #aeaeae;
}

.border-bottom {
  border-bottom: 1px solid #aeaeae;
} 

.special-hr {
  border:1px solid #ffffff0a !important;
  margin: 40px 0px 15px 0px !important;
} 
.btn-text-raya {
  text-decoration: underline !important;
  color: #fff;
  opacity: 0.70;
  transition: all 180ms ease;
  cursor: pointer;
}
.btn-text-raya:hover {
  opacity: 1;
  transition: all 180ms ease;
}

.saldera {
  display: flex;
  justify-content: center;
  height: 100%;
  flex-wrap: nowrap;
  border-top: 1px solid #aeaeae28;
  border-bottom: 1px solid #aeaeae2f;
  align-items: center;
}


.saldera h4 {
  margin-right: auto !important;
} 

.kill-m {
  margin: 0px !important;
}

.yearlist-border {
  border-top: 1px solid #aeaeae28;
  padding: 44px 0px;
  border-bottom: 1px solid #aeaeae2f;
} 

.padd66{
  padding-bottom: 60px !important;
  padding-top: 45px !important;
} 

.epical-select {
  color: #fff;
  padding: 0px 10px;
}

.flex-round-yeah {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
 color: #fff;
 padding-top: 20px;
 cursor: pointer;
 opacity: 0.80;
 transition: all 200ms ease;
}

.flex-round-yeah:hover{
  transition: all 200ms ease;
  opacity: 1;
}

.retoquebb{
  background: #00000015;
  padding: 10px 9px;
  border-bottom: 1px solid #ffffff20;
} 

.btn-on {
  border: 1px solid #ffffff30;
  max-width: 55px;
  width: 100%;
  height: 31px;
  border-radius: 18px;
  cursor: pointer;
  transition: all 150ms ease-in-out;
  margin: 10px 0px;
} 

.btn-on:hover{
  transition: all 150ms ease-in-out;
  border: 1px solid #e7cf7e6b;
} 

.active-on-flex-btn-bor {
  border: 1px solid #e7cf7e;
  transition: all 150ms ease-in-out;
}

.active-on-flex-btn{
  width: 100%;
  max-width: 31px;
  height: 31px;
  opacity: 1 !important;
  background: #0b0b0b !important;
  border-radius: 100px !important;
  margin-left: auto !important;
  margin-right: 0px !important;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 1px solid #454545;
  box-shadow: 0px 0px 4px #000;
} 

.active-on-flex-btn.btn-on-flex img {
  width: 13px;
  height: 13px;
  opacity: 1 !important;
  transition: all 180ms ease-in-out;
}
.btn-on-flex {
  width: 100%;
  max-width: 31px;
  height: 31px;
  background: #000000;
  opacity: 0.85;
  border-radius: 100px;
  margin-left: 0px;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #454545;
  transition: all 170ms ease-in-out;
  box-shadow: 0px 0px 4px #000;
} 

.btn-on-flex img {
  width: 13px;
  height: 13px;
  opacity: 0;
  transition: all 180ms ease-in-out;
} 

.smallis {
  padding-top: 25px;
  color: #fff !important;
  font-size: 15px !important;
} 

.border-bottom-kill {
  border-bottom: 1px solid transparent !important;
} 

.margin-top-009 {
  margin-top: 30px !important;
} 

.contenedor-input-33 {
  display: grid;
  grid-template-columns: 85% 15%;
  align-items: center;
  justify-content: center;
} 

.subinputt {
  
    color: #fff;
    font-size: 18px;
    line-height: 23px;
    padding: 10px 0px;
  
} 

.subinputtx2 {
  
  color: #fff;
  font-size: 25px;
  line-height: 23px;
  padding: 10px 0px;

} 


.obcentere {
  justify-content: center;
  align-items: center;
  display: flex;

} 

.subul {
  line-height: 28px;
 
} 


.contenedor-uniforme {
  display: grid;
  grid-template-columns: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 60px 0px;
} 

.invitaciones {
  background: #0000000a;
  border-radius: 10px;
  height: auto;
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  box-shadow: 0px 0px 25px #000;
} 

.border-0 {
  border-top: 1px solid transparent !important;
  border-bottom:1px solid transparent !important;
} 

.contenedor-doble-master {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 20px 0px;
  
} 

.magiamagia p {
  padding: 10px 0px;
} 

.magiamagia h2 {
  padding: 20px auto;
}

.magiamagia {
  padding: 20px;
} 

.ttt {
  height: 20px !important;
  width: 20px !important;
  cursor: pointer;
  margin-left: 10px !important;
  
}


.center-delcenter {
  display: flex;
  justify-content: center;
  align-items: center;
}


/* ////// clase para el login ///// */

.login-box-domo::after{
  content:"";
  height: 100%;
  width: 100%;
  /* background: url(../imagenes/background/DDD.jpg); */
  position: absolute;
  top: 0;
  z-index:-1;
  background-size: contain;
  background-repeat: no-repeat;
  background-size: 100%;
  opacity: 0.30;
  filter: blur(230px);

} 


.login-box-domo {
  position: relative !important;
} 


/* //////////// hijo bar busqueda ///// */
.busqueda-hijo-derecha-box {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  padding: 0 3%;
  align-items: center;

} 

.busqueda-hijo-derecha-box button {
  border-style: none;
  height: 30px;
  width: auto;
  margin: 0 auto;
  background: #fff3;
  color: #fff;
  cursor: pointer;
}


.busqueda-hijo-izquierda{
  display: grid;
  padding: 0 3%;
  align-items: center;
  justify-content: center;
}




.box-bar-papu {
  width: 100%;
  height: auto;
  display: grid;
  position: relative;
  bottom:0%;
  
}

.box-info-master  {
  display: grid;
  position: relative;
  background: #00000082;
  max-width:700px ;
  margin:auto;
  bottom: 0%;
  left: 0px;
  font-size: 15px;
  width: 100%;
 padding: 1% 0%;
 z-index: 223 !important;
  backdrop-filter: blur(780px);
  border-radius: 10px;
}
@media screen and (max-width:992px) {
  .box-option-account-perfil {
    min-width: 100%;
  }
  .font-perfil{
    display: flex;
    white-space: wrap;
    flex-wrap: wrap;
  }
  .box-box-box-box p {
    display: flex;
    white-space: wrap;
    flex-wrap: wrap;
  }
  .box-box-box-box h2 {
    display: flex;
    white-space: wrap;
    flex-wrap: wrap;
  }
  .box-bar-papu {
    bottom:0% !important;  
  }
  .box-info-master {
    padding:0% 0% ;
  }
  .busqueda-hijo-derecha-box {
    display: grid;
    padding: 3% 3%; 
  }
  .icon-normal-size-30:hover {
    transition: all 200ms ease;
    border: 1px solid #ffffff1f;
  } 
  .input-contenedor-370 {
    grid-template-columns: 100% !important;
  }
  .input-joema-master-geo-contenedor {
    grid-template-columns: 100% !important;
  }





}



.box-busqueda-relative{
  width: 100%;
  display: grid;
  grid-template-columns: 65% 35%;
  position: relative;
  padding: 0% 0%;
  justify-content: center;
} 

.round-btn-ground {
  background: #fff;
  padding: 3px;
  display: grid;
  align-items: center;
  height: 100% !important;
  width:90% !important;
  border-radius: 3px;
}

.search-btn-ground {
  background: #141414 !important;
  height: 100% !important;
  width: 90% !important;
  box-shadow: 0px 0px 2px #ffffff20;
  transition: all 100ms ease-in-out;
} 

.search-btn-ground:hover {
  background: #0000006e !important;
  transition: all 100ms ease-in-out;
  color: #ffefc2;
} 

.negao {
  cursor: not-allowed !important;
  opacity: 0.30 !important;
}




@media  screen and (max-width:992px) {
  
  .scrolled-header-out {
    transition: all 500ms ease-out;
    transform: translateY(0px);
  
  }
  
  .scrolled-header-in {
    transition: all 500ms ease-out;
    transform: translateY(-50px);
  
  }

  .scrolled-footer  {
    transition: all 500ms ease-out;
    /* overflow-y: auto; */
   
    height: auto;
    opacity: 1;
    transform: translateY(120px);
  
}

.scrolled-footer-out {
  transition: all 500ms ease-out;
  transform: translateY(0px);
  
  /* overflow-y: auto; */
  opacity: 1;

}
}

 /* aqui vamos a colocar los sliders manuales */
.btn-slider-container {
  display: grid;
  grid-template-columns: auto auto;
  position: absolute;
  top:50%;
  width: 100%;
  justify-content: space-between;
  z-index: 9999999;
} 


.btn-slider-container button {
  background: #000;
  border-style: none;
  color:#fff;
  padding: 10px;
  border-radius: 1px;
  cursor: pointer;
  border-radius: 2px;
} 

.btn-slider-container button:hover {
  color: #f7e6ad;
  transition: all 120ms ease;
}

.manual-slider.clicked {
  .img-container-pro {
    transition: transform 0.2s ease-in-out; /* Ajusta la duración y el tipo de transición aquí */
    transform: scale(0.9); /* Ajusta el efecto de escala */
  }
} 

@media screen and (min-width:501px) and (max-width:992px) {
  .img-container-pro {
    height: 250px !important;
  }

  .manual-slider.clicked {
    .img-container-pro {
      transition: transform 0.2s ease-in-out; /* Ajusta la duración y el tipo de transición aquí */
      transform: scale(0.98); /* Ajusta el efecto de escala */
    }
  } 
  
}


@media screen and (min-width:0px) and (max-width:500px) {
  .img-container-pro {
    height: 450px !important;
    width: auto  !important;
  }

  .manual-slider.clicked {
    .img-container-pro {
      transition: transform 0.2s ease-in-out; /* Ajusta la duración y el tipo de transición aquí */
      transform: scale(0.98); /* Ajusta el efecto de escala */
    }
  } 
  
}

.input-box-camp {
  display: grid;
  max-width: 1200px;
  margin:0 auto;
  padding: 1rem 0;
  width: 100%;
} 

.logo-tierras {
  display: grid;
}

.img-logo-tierras {
  width: 100% !important;
  max-width: 180px !important;
  margin:10px auto !important;
  cursor: pointer;
}



.img-logo-tierras-02 {
  width: 100% !important;
  max-width: 50px !important;
  margin:10px auto !important;
  padding-right: 20px;
}


.control-total {
  border: 1px solid #fff;
}


.op-none{
  opacity: 0;
  transform: scale(2);
  transition: all 250ms ease;
  animation: buenavainaop 300ms ease-in-out 0ms 1 normal !important;
}

.op-true{
  opacity: 1;
  transform: scale(1);
  transition: all 200ms ease;
  animation: buenavaina 240ms ease 0ms 1 normal;
} 

.d-none {
  display: none !important;
}